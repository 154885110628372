import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import hills from '../../images/hills.jpg';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
      marginBottom: 0,
    },
  },
  imageGrid: {
    padding: '0px 0px 0px 100px',
    // alignSelf: 'flex-end',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '0px 0px 0px 160px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 21px',
    },
  },
  link: {
    paddingBottom: '8px',
    margin: '0 0 8px',
    display: 'block',
    color: COLOR_CORAL,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
});
const GrievancePage = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item sm={6} className={classes.textGrid}>
        <h3 style={{ marginTop: 0, marginBottom: '32px' }}>Report Concerns</h3>
        <Grid container>
          <Grid item xs={12}>
            <p>
              Vow’s channel for reporting issues of concern presented here
              targets external stakeholders who have feedback or concerns
              related to our business activities or potential non-compliance
              with our Code of Conduct. All reports will be taken seriously and
              processed according to established procedures:
              <ol>
                <li>
                  {' '}
                  All reports submitted will be received and reviewed by Vow
                  management.
                </li>
                <li>
                  Responsibility for defining solution approach related to the
                  issue of concern will be assigned.
                </li>
                <li>
                  The submitter receives information about actions taken to
                  resolve the issue of concern.
                </li>
              </ol>
              If you have issues of concern you would like to share with us,
              please use the form below.
              <br />
              <br />
              The form is available in several languages.
              <br />
              <br />
              Vow employees are encouraged to report issues of concern according
              to our internal whistleblowing procedure.
            </p>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://vowasa.integrity.complylog.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Report Concerns Form
            </a>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20Supplier%20Code%20of%20Conduct.pdf?alt=media&token=fbf24ab2-3db8-484d-95ef-7c1cdd04737b"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow Supplier Code of Conduct - PDF
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} className={classes.imageGrid}>
        <img src={hills} alt="hills" />
      </Grid>
    </Grid>
  );
};

export default GrievancePage;
