import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import sustainability from '../../mockData/sustainability';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';
import weSupport from '../../images/un-global.webp';
import ImgCard2 from '../ImgCard2';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      marginTop: 40,
      marginBottom: 0,
    },
  },
  container2: {
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 40,
    '@media (max-width:600px)': {
      marginTop: 40,
      marginBottom: 0,
    },
  },
  imageGrid: {
    padding: '0px 0px 0px 140px',
    alignSelf: 'flex-end',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '0px 0px 0px 160px',
    maxWidth: 720,
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: COLOR_CORAL,
      },
      '&:visited': {
        color: COLOR_RED_VISITED,
      },
    },
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
  textGrid2: {
    alignSelf: 'center',
    '& p': {
      maxWidth: 600,
    },
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    padding: '20px 0px 40px 100px',
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 80px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
    },
  },
  emblemcontainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 'none',
    paddingLeft: '8%',
    '@media (max-width:1800px)': {
      paddingLeft: '10%',
    },
    '@media (max-width:1200px)': {
      paddingLeft: '12%',
    },
    '@media (max-width:600px)': {
      paddingLeft: 'none',
      justifyContent: 'center',
    },
  },
});

const s = 's';

const SustainabilityPage = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item sm={6} className={classes.textGrid}>
        <h3 style={{ marginTop: 0 }}>
          UN SDG
          <span style={{ fontSize: 23 }}>{s}</span> - UN Sustainable Development
          Goals
        </h3>
        <p>
          Our entire business is built on the fundamental belief that we need to
          take better care of the world. We provide technology and solutions
          that enable our customers to manage their waste and energy challenges
          in a sustainable manner.
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://sustainabledevelopment.un.org/sdgs"
            style={{ padding: '6px' }}
          >
            The United Nations’ Sustainable Development Goals
          </a>
          (SDGs) guide us in our work. The SDGs is a collection of 17 goals set
          by the United Nations. Each goal has a separate list of targets to
          achieve, and achieving all 169 targets, the 17 SDGs will be met. The
          SDG are a global call of action to end poverty, protect the planet and
          ensure that all people enjoy peace and prosperity. In Vow we are
          working to meet several of these goals.
        </p>
        <p>
          Since 2020 Vow has been a member of UN Global Compact and comitted to
          its principles in the areas of human rights, labour, the environment
          and anti-corruption.
        </p>
      </Grid>
      <Grid item sm={6} className={classes.imageGrid}>
        <img src={weSupport} alt="UN development goals" />
      </Grid>
      {/* Here is the old sustainable values grid */}
      {/* <Grid item sm={6} className={classes.imageGrid}>
        <Grid container spacing={2}>
          {sustainability.map(item => (
            <Grid item sm={12}>
              <ImgCard {...item} />
            </Grid>
          ))}
        </Grid>
      </Grid> */}
      <Grid item sm={6} className={classes.textGrid}>
        <h3 style={{ marginTop: 60, marginBottom: 60 }}>
          Prioritised SDGs - our areas of impact
        </h3>
      </Grid>
      <Grid item sm={6} className={classes.imageGrid}>
        <div />
      </Grid>
      <Container className={classes.emblemcontainer}>
        {sustainability.map(item => (
          <ImgCard2 img={item.image} text={item.text} />
        ))}
      </Container>
    </Grid>
  );
};

export default SustainabilityPage;
