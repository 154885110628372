import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import ourApproach from '../../images/ourApproach.jpg';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
      marginBottom: 0,
    },
  },
  imageGrid: {
    padding: '0px 0px 0px 100px',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '0px 0px 0px 160px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
  link: {
    margin: '0 0 8px',
    display: 'block',
    color: COLOR_CORAL,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
  pretext: {
    paddingRight: 24,
    maxWidth: 600,
    marginBottom: 0,
  },
  preTextExtra: {
    margin: 2,
  },
  bodyText: {
    paddingRight: 32,
    maxWidth: 600,
  },
});
const SustainabilityPage = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item sm={6} className={classes.textGrid}>
        <h3 style={{ marginTop: 0 }}>Our approach to sustainability</h3>
        <p className={classes.bodyText}>
          Sustainability is the entire reason for Vow being in business. Vow’s
          solutions purify wastewater, convert biomass and waste into valuable
          resources, and generate CO2-neutral energy and biocarbon that
          decarbonise industrial processes for customers in cruise, aquaculture
          and a wide range of landbased industries. These solutions emerge from
          the group’s mission to maximise environmental sustainability impact,
          both on land and at sea, creating long-term value for customers,
          shareholders, society, and the environment. In the daily work to
          pursue the group’s purpose and deliver on the group’s mission, Vow is
          directed by a set of core values:
        </p>
        <div className={classes.pretext}>
          <p className={classes.preTextExtra}>
            <span style={{ fontWeight: 'bold' }}>Trust</span> is a key building
            block of the Vow culture
          </p>
          <p className={classes.preTextExtra}>
            <span style={{ fontWeight: 'bold' }}>Responsible</span> business
            conduct is fundamental for all we do
          </p>
          <p className={classes.preTextExtra}>
            <span style={{ fontWeight: 'bold' }}>Inclusive</span> towards each
            other, partners, and stakeholders
          </p>
          <p className={classes.preTextExtra}>
            <span style={{ fontWeight: 'bold' }}>Passionate</span> about
            preventing pollution, giving waste value, and mitigating climate
            change
          </p>
        </div>
        <p className={classes.bodyText} style={{ paddingTop: 10 }}>
          Vow helps customers on their pathway to a net-zero emission future
          aligned with the Paris Agreement. Net-zero ambitions and the UN
          Sustainable Development Goals (SDGs) play a key role in the group’s
          strategy development. The green transition involves a great
          opportunity for Vow with industry demanding technology to decarbonise
          and prevent pollution. Consequently, Vow sees a great potential for
          continued profitable growth for all business activities in its key
          markets.
        </p>
      </Grid>
      <Grid item sm={6} className={classes.imageGrid}>
        <img src={ourApproach} alt="vow sustainability" />
      </Grid>
    </Grid>
  );
};

export default SustainabilityPage;
