import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import esgResources from '../../images/esgResources.jpg';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
      marginBottom: 0,
    },
  },
  title: {
    marginTop: 60,
    maxWidth: 720,
    paddingLeft: 120,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 30px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 14px',
    },
  },
  titleSmall: {
    marginTop: 0,
    marginBottom: 32,
  },
  titleSmallExtra: {
    marginTop: 32,
    marginBottom: 32,
  },
  imageGrid: {
    padding: '0px 0px 0px 100px',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 30px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '0px 0px 0px 120px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 30px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 14px',
    },
  },
  textGridMiddle: {
    padding: '0px 0px 0px 60px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 16px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 12px',
    },
  },
  link: {
    fontSize: 15,
    paddingBottom: '8px',
    margin: '0 0 8px',
    display: 'block',
    color: COLOR_CORAL,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
});
const SustainabilityPage = () => {
  const classes = useStyles();
  return (
    <>
      <h3 className={classes.title}>ESG Reporting</h3>
      <Grid container className={classes.container}>
        <Grid item sm={3} className={classes.textGrid}>
          <h4 className={classes.titleSmall}>Sustainability reports</h4>
          <Grid container>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FGRI%20Content%20Index%202023.pdf?alt=media&token=3be66137-b7e3-4482-acd3-4189d64c0199"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                GRI Content Index 2023
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20-%20Annual%20and%20Sustainability%20Report%202023.pdf?alt=media&token=f90f16a5-e936-4575-b5c8-14b1c1184f98"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Annual and Sustainability Report 2023
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2Fvowasa-2023-12-31-en_v2.zip?alt=media&token=8663fb07-245a-40e5-b8a1-499d6a364ab3"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Annual and Sustainability Report 2023 (ESEF)
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FAnnual%20and%20Sustainability%20Report%202022.pdf?alt=media&token=7da93d4e-f963-4808-94a2-48f2247ed0a7"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Annual and Sustainability Report 2022
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FGRI%20content%20index%202022.pdf?alt=media&token=c2aea94c-bdc5-43f9-aa76-73c9e5228ec5"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                GRI Content Index 2022
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2Fvow-annual-and-sustainability-report-2021.pdf?alt=media&token=70943b76-2edc-4ee3-b0c5-ad29831fb19f"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Annual and Sustainability Report 2021
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2Fvowasa-2021-12-31-en.zip?alt=media&token=8acd4ac7-d937-4d84-9aa8-8fb9ef0671d8"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Annual and Sustainability Report 2021 (ESEF)
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FGRI%20index%202021.pdf?alt=media&token=748fe8bb-e357-4c96-a326-7d03968bbe76"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                GRI index 2021
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FMaterial%20sustainability%20topics%202021.pdf?alt=media&token=e6a7c83e-80f7-47be-acb3-a838dea8243f"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Material Sustainability Topics 2021
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FReporting%20on%202021%20goals.pdf?alt=media&token=d4f5f4aa-978b-4f27-9bf1-9e80bbbd01bb"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Reporting on 2021 goals
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FSustainability%20Report%202020%20for%20print.pdf?alt=media&token=68a2f525-264a-4fc3-aea1-0926acd4d633"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Sustainability Report 2020 (Print)
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FSustainability%20Report%202020%20for%20web.pdf?alt=media&token=68399604-a031-4c56-818f-4c1d028fa0db"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Sustainability Report 2020 (Web)
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FGRI%20Index%202020.pdf?alt=media&token=a98b8503-16e6-40c3-b7a1-ad152013510b"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                GRI index 2020
              </a>
            </Grid>
            <h4 className={classes.titleSmallExtra}>Climate reports</h4>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20TCFD%20Report%202023.pdf?alt=media&token=3c457e3c-f5e8-4044-885f-0b69270e9424"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Vow TCFD Report 2023
              </a>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20TCFD%20Report%202022_07.07.2023.pdf?alt=media&token=c5b56cd7-5524-44bc-af71-5951917c36d7"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Vow TCFD Report 2022
              </a>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20TCFD%20Report%202021.pdf?alt=media&token=6e4fd60b-7de3-4a2b-837f-dbc01d63b270"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Vow TCFD Report 2021
              </a>
            </Grid>
            <h4 className={classes.titleSmallExtra}>HSEQ</h4>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVOW%20HSE%20policy.pdf?alt=media&token=80859380-e777-497d-bb95-91b886a97395"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Vow HSE policy
              </a>
            </Grid>
          </Grid>
        </Grid>
        {/* midde items */}
        <Grid item sm={3} className={classes.textGridMiddle}>
          <h4 className={classes.titleSmall}>Ethics and governance</h4>
          <Grid container>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FTransparancyAct_2023_vf.pdf?alt=media&token=829ca59a-3005-42b8-8478-3e03ce90e072"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Transparency Act – Account 2023
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20Code%20of%20Conduct%20.pdf?alt=media&token=16ca23d6-55dd-4497-87a5-ccda0a7292b0"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Vow Supplier Code of Conduct
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FVow%20Employee%20Code%20of%20Conduct.pdf?alt=media&token=d1badf3d-803c-469c-9065-36a678c0f06b"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Vow Employee Code of Conduct
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FSustainability%20governance.pdf?alt=media&token=1166fddf-fd0c-4ecb-a986-ae002830df3d"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Sustainability Governance
              </a>
            </Grid>
            <Grid item xs={12}>
              <Link
                className={classes.link}
                to="/sustainability/grievance-mechanism"
              >
                Grievance mechanism
              </Link>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FTransparencyAct_2022_Published%20version.pdf?alt=media&token=69738f77-8f75-4fea-890c-2e86bb7c21a5"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Transparency Act – Account 2022
              </a>
            </Grid>
            <h4 className={classes.titleSmallExtra}>Green financing</h4>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FGreen%20Loan%20Statements.pdf?alt=media&token=51338381-7d7a-47cc-9bff-132231ca7e60"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Green Loan Statements
              </a>
            </Grid>
            <h4 className={classes.titleSmallExtra}>Certifications</h4>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FISO%209001%20certificate%20Scanship%20AS.PDF?alt=media&token=04eb32cc-18e0-403f-a4e7-53f2cc64ab76"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                ISO 9001 certificate Scanship AS
              </a>
            </Grid>
            <h4 className={classes.titleSmallExtra}>
              Diversity, equality & inclusion
            </h4>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FEquality%20Statement%20Scanship%202023%20v270624.pdf?alt=media&token=b6af77ae-3505-4ce7-a1bd-548de6571777"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Equality Statement 2023 Scanship AS
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FEquality%20Statement%202022%20Scanship%20AS.pdf?alt=media&token=f0824d8f-331a-4e77-96f1-40ac3c431203"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Equality Statement 2022 Scanship AS
              </a>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/sustainability%2FEquality%20Statement%20Scanship%202021.pdf?alt=media&token=d2a37db4-29e4-4ea6-b540-fc0cda6eb10b"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Equality Statement 2021 Scanship AS
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <img src={esgResources} alt="vow sustainability" />
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item sm={6} className={classes.textGrid}>
          <h4
            style={{
              fontSize: '1,2em',
              fontWeight: 'bold',
              paddingTop: '16px',
              paddingBottom: '16px',
            }}
          >
            For more information, please contact
          </h4>
          <p>
            Tina Tønnessen – Chief Financial Officer (CFO)
            <br />
            Tel: +47 406 395 56
            <br />
            tina.tonnessen@vowasa.com
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default SustainabilityPage;
