export default [
  // {
  //   image: '',
  //   text:
  //     'Since 2020 Vow has been a member of UN Global Compact and comitted to its principles in the areas of human rights, labour, the environment and anti-corruption.',
  //   textColor: '#0e2f50',
  //   color: '#FFF',
  // },
  {
    image:
      'https://sustainabledevelopment.un.org/content/images/E_SDG_Icons-07.jpg',
    text:
      'With our waste to energy carbon capture technology we create clean energy from waste for immediate use on board cruise ships and in various land-based industries. With this, we are advancing SDG 7.',
    color: '#FBC412',
  },
  {
    image:
      'https://sustainabledevelopment.un.org/content/images/E_SDG_Icons-09.jpg',
    text:
      'With our innovations within wastewater purification and waste to energy we help to improve the sustainability of sea traffic and land-based industries. With these technologies, we are advancing SDG 9.',
    color: '#F26A2E',
  },
  {
    image:
      'https://sustainabledevelopment.un.org/content/images/E_SDG_Icons-12.jpg',
    text:
      'With our technologies and solutions for wastewater purification and waste valorisation that bring an end to waste, we are promoting sustainable production and consumption, and thus advancing SDG 12',
    color: '#BE8D2C',
  },
  {
    image:
      'https://sustainabledevelopment.un.org/content/images/E_SDG_Icons-13.jpg',
    text:
      'With our waste to energy carbon capture technology we will reduce the use of fossil fuels and reduce CO2 emissions, we are advancing SDG 13.',
    color: '#407F46',
  },
  {
    image:
      'https://sustainabledevelopment.un.org/content/images/E_SDG_Icons-14.jpg',
    text:
      'With our technologies for wastewater purification, food waste and garbage handling onboard cruise ships along with our solutions for the aquaculture industry, we are advancing SDG 14.',
    color: '#1F97D4',
  },
];
