import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  media: {
    height: 210,
    width: '100%',
  },
  emblemCard: {
    width: 220,
    borderRadius: 0,
    height: 520,
    marginRight: 20,
    marginTop: 20,
  },
});

const ImgCard2 = ({ img, text }) => {
  const classes = useStyles();
  return (
    <Card className={classes.emblemCard}>
      <CardMedia
        className={classes.media}
        image={img}
        title="Contemplative Reptile"
      />
      <CardContent>
        {/* <Typography variant="body2" color="textSecondary" component="p">
          {text}
        </Typography> */}
        <p>{text}</p>
      </CardContent>
    </Card>
  );
};

export default ImgCard2;
